import React from "react";
import { Divider } from "../../components/Divider";
import Layout from "../../components/Layout";
import { graphql, Link } from "gatsby";

export default function Index({ data, location }) {
  let pages = data.allContentfulDevelopmentPages.nodes;

  return (
    <Layout
      location={location}
      title="Development"
      description="Nielsen has a myriad of web applications that use various tech stacks. Our primary goal of GDS is to align our products’ visual and technical foundations. This will improve our processes and help us at every stage of software development. GDS is being developed into two sets of reusable components, MAF + GDS components, and GDS Web Components."
    >
      <div className="d-flex flex-column gap-space-800">
        <div className="section">
          <h2>MAF + GDS</h2>
          <p className="description mt-space-400">
            MAF (Media Application Framework) is our foundational web platform
            on which most of our key applications are built. The GDS and MAF
            teams have worked closely together to build elegant, reusable React
            components and patterns. These will underpin our key future Nielsen
            applications.
          </p>
          <p className="description my-space-400">
            Check out MAF's Storybook for more information about the MAF + GDS
            components. You must be logged into the VPN to view.
          </p>
          <a
            target="_blank"
            rel="noreferrer"
            href="http://webcomponents.maf.nielsenmedia.com/gds"
          >
            <gds-button color="tertiary" icon-position="right" icon="export">
              MAF Storybook
            </gds-button>
          </a>
        </div>
        <Divider />
        <div className="section">
          <h2>GDS Web Components</h2>
          <p className="description mt-space-400">
            Currently, at least five different Javascript frameworks are used
            throughout Nielsen products. This complexity means it's nearly
            impossible to have a consistent component library. To improve this,
            we have begun to build a tech stack agnostic implementation of GDS
            using Web Components. This will allow us to provide consistent
            styling, usability, and repeatability across all our web platforms.
          </p>
          <p className="description my-space-400">
            For more information about GDS Web Components, check out these
            guides to get started.
          </p>
          <div className="button-group d-flex gap-space-500">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://nielsen-media.gitlab.io/ma/design-libraries/gds-web-components/development/storybook/?path=/story/foundations-icons-interface-icons-icons--icons"
            >
              <gds-button color="tertiary" icon-position="right" icon="export">
                Web Components Storybook
              </gds-button>
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://gitlab.com/nielsen-media/ma/design-libraries/gds-web-components/-/packages"
            >
              <gds-button color="tertiary" icon-position="right" icon="export">
                Web Components Package Registry
              </gds-button>
            </a>
          </div>
          <div className="web-component-links d-flex flex-column mt-space-500">
            {pages
              .sort(function (a, b) {
                return a.order - b.order;
              })
              .map((page) => (
                <Link
                  to={page.name.toLowerCase().replace(/\s/g, "-") + "/"}
                  className="my-space-300"
                >
                  <gds-link color="secondary" underlined="true">
                    {page.name}
                  </gds-link>
                </Link>
              ))}
          </div>
        </div>
      </div>
    </Layout>
  );
}

export const query = graphql`
  query DevelopmentPages {
    allContentfulDevelopmentPages {
      nodes {
        name
        order
      }
    }
  }
`;
